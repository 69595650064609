import "./Button.css";

interface Properties {
  text: string;
  url?: string;
}

export const Button = ({ text, url }: Properties) => {
  const redirect = () => window.location.replace(url || "");
  return (
    <button onClick={url ? redirect : undefined} className="my-button">
      {text}
    </button>
  );
};
