import "./Banner.css";
import { useMobile } from "../../hooks/useMobile";
import { GirlSection } from "../Pages/Landing/GirlSection/GirlSection";
import { LaptopSection } from "../Pages/Landing/LaptopSection/LaptopSection";
import { RoadmapSection } from "../Pages/Landing/RoadmapSection/RoadmapSection";
import { WorldwideSection } from "../Pages/Landing/WorldwideSection/WorldwideSection";
import { FAQ } from "../FAQ/FAQ";

const contactUs = "Buy course now!";
const stripeUrl = "https://buy.stripe.com/cN2dUk5aKfcp2Ji6oo";

export const Banner = () => {
  const isMobile = useMobile();
  return (
    <div className="banner">
      <GirlSection
        isMobile={isMobile}
        contactUs={contactUs}
        stripeUrl={stripeUrl}
      />
      <LaptopSection isMobile={isMobile} />
      <RoadmapSection isMobile={isMobile} />
      <WorldwideSection
        isMobile={isMobile}
        contactUs={contactUs}
        stripeUrl={stripeUrl}
      />
      <FAQ />
    </div>
  );
};
