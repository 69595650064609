import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

export const useValidateToken = () => {
  const navigate = useNavigate();
  useEffect(() => {
    const authToken = sessionStorage.getItem("bearer-token");

    if (authToken) {
      navigate("/content");
    }

    if (!authToken) {
      navigate("/members");
    }
  }, []);
};
