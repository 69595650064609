import { useMobile } from "../../../hooks/useMobile";
import "./CookiesPolicy.css";

export const CookiesPolicy = () => {
  const isMobile = useMobile();
  return (
    <div
      className={
        !isMobile
          ? "cookies-policy-container-flex"
          : "cookies-policy-container-flex-mobile"
      }
    >
      <div className="cookies-policy-container">
        <div className="cookies-policy-content">
          <h1>Cookie Policy</h1>

          <h2>WHAT ARE COOKIES?</h2>
          <p>
            Cookies and other similar technologies such as local shared objects,
            flash cookies, or pixels are tools used by web servers to store and
            retrieve information about their visitors, as well as to ensure the
            proper functioning of the site. By using these devices, the web
            server is able to remember certain data about the user, such as
            their preferences for viewing pages on that server, username and
            password, products of interest, etc.
          </p>

          <h2>COOKIES COVERED BY THE REGULATIONS AND EXEMPTED COOKIES</h2>
          <p>
            According to the EU directive, the cookies that require informed
            consent from the user are the cookies of analytics and advertising
            and affiliation, exempting those of a technical nature and those
            necessary for the functioning of the website or the provision of
            services expressly requested by the user.
          </p>

          <h2>TYPES OF COOKIES</h2>

          <h3>According to purpose</h3>
          <ul>
            <li>
              <strong>Technical and functional cookies:</strong> These allow
              users to navigate through a website, platform, or application and
              use the different options or services available.
            </li>
            <li>
              <strong>Analytics cookies:</strong> These enable the responsible
              party to track and analyze the behavior of users on the websites
              they are linked to. The information collected is used for
              measuring the activity of the websites and creating navigation
              profiles of the users, with the aim of making improvements based
              on the analysis of usage data.
            </li>
            <li>
              <strong>Advertising cookies:</strong> These allow for the
              effective management of advertising spaces on a website,
              application, or platform, based on criteria such as the edited
              content or the frequency of displaying ads.
            </li>
            <li>
              <strong>Behavioral advertising cookies:</strong> These gather
              information about the user's personal preferences and choices
              (retargeting) to enable the effective management of advertising
              spaces on a website, application, or platform.
            </li>
            <li>
              <strong>Social media cookies:</strong> These are set by social
              media platforms on the services to allow users to share content
              with their friends and networks. Social media platforms have the
              ability to track your online activity outside of the services,
              which may affect the content and messages you see on other visited
              services.
            </li>
            <li>
              <strong>Affiliate cookies:</strong> These allow tracking of visits
              from other websites with which the website has an affiliate
              agreement.
            </li>
            <li>
              <strong>Security cookies:</strong> These store encrypted
              information to prevent the data stored in them from being
              vulnerable to malicious attacks by third parties.
            </li>
          </ul>

          <h3>According to ownership</h3>
          <ul>
            <li>
              <strong>First-party cookies:</strong> These are sent to the user's
              terminal equipment from a device or domain managed by the website
              owner.
            </li>
            <li>
              <strong>Third-party cookies:</strong> These are sent to the user's
              terminal equipment from a device or domain that is not managed by
              the website owner but by another entity that processes data
              obtained through cookies.
            </li>
          </ul>

          <h3>According to the storage period</h3>
          <ul>
            <li>
              <strong>Session cookies:</strong> These are designed to collect
              and store data while the user accesses a website.
            </li>
            <li>
              <strong>Persistent cookies:</strong> These remain stored on the
              user's device and can be accessed and processed during a defined
              period by the party responsible for the cookie.
            </li>
          </ul>

          <h2>COOKIES USED ON THIS WEBSITE</h2>
          <p>
            Here you can find a list of the specific cookies used on this
            website.
          </p>
        </div>
      </div>
    </div>
  );
};
