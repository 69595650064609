import "./RoadmapSection.css";
import roadmapLogo from "../../../../images-local/roadmap.png";

interface Props {
  isMobile: boolean;
}

export const RoadmapSection = ({ isMobile }: Props) => {
  return (
    <>
      {!isMobile ? (
        <section className="roadmap-image">
          <img alt="roadmapLogo" src={roadmapLogo} />
        </section>
      ) : (
        <></>
      )}
    </>
  );
};
