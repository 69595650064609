import { useMobile } from "../../hooks/useMobile";
import "./NavBar.css";

export const NavBar = () => {
  const isMobile = useMobile();
  return (
    <div className={isMobile ? "nav-bar-mobile" : "nav-bar"}>
      <a href="/who-we-are">Who we are</a>
      <a href="mailto: apicanta.llc@gmail.com">Contact</a>
      <a href="/members">Members</a>
    </div>
  );
};
