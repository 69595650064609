// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
export const firebaseConfig = {
  apiKey: "AIzaSyC1CYVwwqS0tTNGlxTmtrW8Wtf7Wd1hEGM",
  authDomain: "apicanta-backend.firebaseapp.com",
  projectId: "apicanta-backend",
  storageBucket: "apicanta-backend.appspot.com",
  messagingSenderId: "647605406528",
  appId: "1:647605406528:web:0de2819c3e68c60a9008a5",
  measurementId: "G-ZLYTQSNXBT",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);

// const analytics = getAnalytics(app);
