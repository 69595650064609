import { useState } from "react";
import "./Login.css";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import { signInWithEmailAndPassword } from "firebase/auth";
import { auth } from "../../../firebase";
import { useNavigate } from "react-router-dom";

const LoginPage = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const handleEmailChange = (e: any) => {
    setEmail(e.target.value);
  };

  const handlePasswordChange = (e: any) => {
    setPassword(e.target.value);
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    // Add your login logic here
    signInWithEmailAndPassword(auth, email, password)
      .then((userCredential: any) => {
        // User logged in successfully
        sessionStorage.setItem("bearer-token", JSON.stringify(userCredential));
        navigate("/content");
      })
      .catch((error: any) => {
        // Handle login errors
        console.log(error.message);
      });
  };

  return (
    <div className="login-container">
      <form className="login-form" onSubmit={handleSubmit}>
        <h2>Apicanta Login</h2>
        <div className="form-group">
          <label>Email:</label>
          <input
            type="email"
            value={email}
            onChange={handleEmailChange}
            required
          />
        </div>
        <div className="form-group">
          <label>Password:</label>
          <input
            type="password"
            value={password}
            onChange={handlePasswordChange}
            required
          />
        </div>
        <button style={{ padding: "10px" }} type="submit">
          Login
        </button>
      </form>
    </div>
  );
};

export default LoginPage;
