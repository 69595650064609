import "./Header.css";
import apicantaLogo from "../../images-local/apicanta-logo.png";
import { useMobile } from "../../hooks/useMobile";

export const Header = () => {
  const isMobile = useMobile();
  return (
    <a href="/">
      <div className="header">
        <img
          className={!isMobile ? "header-logo" : "header-logo-mobile"}
          alt="worldwideLogo"
          src={apicantaLogo}
        />
        <span>Apicanta</span>
        <img
          className={!isMobile ? "header-logo" : "header-logo-mobile"}
          alt="worldwideLogo"
          src={apicantaLogo}
        />
      </div>
    </a>
  );
};
