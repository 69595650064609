import Faq from "react-faq-component";
import "./FAQ.css";
import { useMobile } from "../../hooks/useMobile";

const data = {
  title: "FAQ (How it works)",
  rows: [
    {
      title: "What is the price of the course?",
      content:
        "The course is priced at two installments. The first payment is $450, followed by a second payment of $1050.",
    },
    {
      title: "Are there additional fees for monthly mentorships?",
      content:
        "Yes, if you choose to renew monthly mentorships, there will be additional fees. The pricing for these mentorships varies depending on the specific mentorship needed.",
    },
    {
      title: "How much does the monthly mentorship cost?",
      content:
        "The cost of monthly mentorships varies depending on the specific mentorship required. Please contact our team or refer to our pricing information for details on the pricing of individual mentorship programs.",
    },
    {
      title: "Can you provide more information about the cost of mentorships?",
      content:
        "The cost of mentorships is determined by factors such as the duration, intensity, and specialized focus of the mentorship. We offer a range of mentorship options to cater to different needs, and each has its own pricing structure. To get accurate pricing information for the mentorship you're interested in, please reach out to our team or consult our pricing details.",
    },
    {
      title: "Are there any discounts or promotions available?",
      content:
        "At times, we may offer discounts or promotional offers for our courses and mentorships. It's best to check our website or contact our team directly to inquire about any current discounts or promotions that may be available.",
    },
    {
      title: "Can I pay for the course in installments?",
      content:
        "Yes, the course is divided into two installments. The first payment is $450, and the second payment is $1050. This payment plan allows for greater flexibility in managing the cost of the course.",
    },
    {
      title: "What payment methods do you accept?",
      content:
        "We accept various payment methods, including credit cards, debit cards, and online payment platforms. Specific details regarding accepted payment methods will be provided during the enrollment process or on our website.",
    },
    {
      title: "Is there a refund policy?",
      content:
        "We have a refund policy in place. However, the specifics of the policy may vary depending on the course or mentorship program you enroll in. It's recommended to review our refund policy or contact our team for more information regarding refunds and cancellations.",
    },
    {
      title:
        "Can I receive financial assistance or scholarships for the course?",
      content:
        "We understand that financial assistance can be important for some individuals. While we don't offer scholarships directly, we encourage you to explore external scholarship opportunities or inquire about potential financial aid options with educational funding institutions in your area.",
    },
    {
      title: "Can I upgrade or change my mentorship program after enrolling?",
      content:
        "Depending on availability and program requirements, it may be possible to upgrade or change your mentorship program after enrolling. However, this would need to be discussed with our team to assess the feasibility and any associated costs or adjustments to the payment structure.",
    },
  ],
};
export const FAQ = () => {
  const isMobile = useMobile();

  const styles = {
    bgColor: "black",
    titleTextColor: "rgb(75, 57, 238)",
    rowTitleColor: "blue",
    rowContentColor: "white",
    arrowColor: "white",
  };

  const config = {
    // animate: true,
    ...(!isMobile ? { animate: true } : {}),

    ...(!isMobile ? { arrowIcon: "V" } : {}),
    tabFocus: true,
  };

  return (
    <div className={isMobile ? "faq-mobile" : "faq"}>
      <Faq data={data} styles={styles} config={config} />
    </div>
  );
};
