import "./GirlSection.css";
import girlLogo from "../../../../images-local/girl.png";
import { Button } from "../../../Button/Button";

interface Props {
  isMobile: boolean;
  contactUs: string;
  stripeUrl: string;
}

export const GirlSection = ({ isMobile, contactUs, stripeUrl }: Props) => {
  return (
    <>
      {!isMobile ? (
        <section className="girl-container">
          <img alt="girlLogo" className="girl-image" src={girlLogo} />
          <div className="girl-right-side">
            <div>
              Unleash your business's potential with our expert-led course.
              Learn vital strategies in marketing, sales, finance, and
              leadership. Enroll now for success!
            </div>
            <Button text={contactUs} url={stripeUrl} />
          </div>
        </section>
      ) : (
        <>
          <img alt="girlLogo" className="girl-image" src={girlLogo} />
          <div className="girl-right-side">
            <div>
              Unleash your business's potential with our expert-led course.
              Learn vital strategies in marketing, sales, finance, and
              leadership. Enroll now for success!
            </div>
            <Button text={contactUs} url={stripeUrl} />
          </div>
        </>
      )}
    </>
  );
};
