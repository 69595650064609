import "./LaptopSection.css";
import laptopLogo from "../../../../images-local/laptop.png";

interface Props {
  isMobile: boolean;
}

export const LaptopSection = ({ isMobile }: Props) => {
  return (
    <>
      {!isMobile ? (
        <section className="laptop-container">
          <img className="laptop-image" alt="laptop" src={laptopLogo} />
          <div>
            <div className="we-came-here">We came here out of</div>
            <div className="passion">passion</div>
            <div className="paragraph">
              We are a group of friends who love design and creativity, that's
              why we discovered our purpose: through our developing knowledge,
              to help all those who want to have digital businesses. The future
              has already arrived and we are working hard to make it reach
              everyone.
            </div>
          </div>
        </section>
      ) : (
        <>
          <section className="laptop-container">
            <img className="laptop-image" alt="laptop" src={laptopLogo} />
          </section>
          <section className="laptop-container">
            <div>
              <div className="we-came-here">We came here out of</div>
              <div className="passion">passion</div>
              <div className="paragraph">
                We are a group of friends who love design and creativity, that's
                why we discovered our purpose: through our developing knowledge,
                to help all those who want to have digital businesses. The
                future has already arrived and we are working hard to make it
                reach everyone.
              </div>
            </div>
          </section>
        </>
      )}
    </>
  );
};
