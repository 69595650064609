import "./WorldwideSection.css";
import worldwideLogo from "../../../../images-local/worldwide.png";
import weProvideLogo from "../../../../images-local/we-provide.png";
import { Button } from "../../../Button/Button";

interface Props {
  isMobile: boolean;
  contactUs: string;
  stripeUrl: string;
}

export const WorldwideSection = ({ isMobile, contactUs, stripeUrl }: Props) => {
  return (
    <>
      {!isMobile ? (
        <>
          <section className="worldwide-image-container">
            <img
              className="worldwide-image"
              alt="worldwideLogo"
              src={worldwideLogo}
            />
            <img
              className="weprovide-image"
              alt="weProvideLogo"
              src={weProvideLogo}
            />
          </section>
          <section className="bags-container">
            <div className="bags-text">
              Unlock the power of growing your business and take charge of your
              success today!
            </div>
            <Button text={contactUs} url={stripeUrl} />
          </section>
        </>
      ) : (
        <>
          <section className="worldwide-image-container">
            <img
              className="worldwide-image-mobile"
              alt="worldwideLogo"
              src={worldwideLogo}
            />
            <img
              className="weprovide-image-mobile"
              alt="weProvideLogo"
              src={weProvideLogo}
            />
          </section>
          <section className="bags-container">
            <div className="bags-text">
              Unlock the power of growing your business and take charge of your
              success today!
            </div>
            <Button text={contactUs} url={stripeUrl} />
          </section>
        </>
      )}
    </>
  );
};
