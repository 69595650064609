import "./Footer.css";
import apicantaWhiteLogo from "../../images-local/apicanta-white.png";
import { useMobile } from "../../hooks/useMobile";

export const Footer = () => {
  const isMobile = useMobile();
  return (
    <div className={isMobile ? "footer-mobile" : "footer"}>
      <div className="links">
        <a href="/legal-disclaimer">Legal Disclaimer</a>
        <span>|</span>
        <a href="/cookies-policy">Cookies Policy</a>
        <span>|</span>
        <a href="/privacy-policy">Privacy Policy</a>
      </div>
      <img
        className="apicanta-white-logo-image"
        alt="apicantaWhiteLogo"
        src={apicantaWhiteLogo}
      />
    </div>
  );
};
