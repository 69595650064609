import { useMobile } from "../../../hooks/useMobile";
import "./PrivacyPolicy.css";

export const PrivacyPolicy = () => {
  const isMobile = useMobile();
  return (
    <div
      className={
        !isMobile
          ? "privacy-policy-container-flex"
          : "privacy-policy-container-flex-mobile"
      }
    >
      <div className="privacy-policy-container">
        <div className="privacy-policy-content">
          <h1>Privacy Policy</h1>

          <h2>Information Collection and Use</h2>
          <p>
            This website collects certain personal information from its users
            for the purpose of providing and improving the services offered. The
            types of information collected may include:
            <ul>
              <li>Full name</li>
              <li>Email address</li>
              <li>Phone number</li>
              <li>Address</li>
              <li>Other relevant information</li>
            </ul>
            The collected information is used to communicate with the users,
            provide requested services, and enhance the user experience on the
            website.
          </p>

          <h2>Data Protection</h2>
          <p>
            We take appropriate measures to ensure the security of the personal
            information provided by our users. However, no method of data
            transmission or storage is 100% secure, and we cannot guarantee the
            absolute security of user data.
          </p>

          <h2>Third-Party Services</h2>
          <p>
            This website may use third-party services that collect, monitor, and
            analyze user information to improve functionality and user
            experience. These services have their own privacy policies governing
            the use of data.
          </p>

          <h2>Cookies</h2>
          <p>
            This website may use cookies to enhance the user experience and
            provide personalized services. By using this website, users consent
            to the use of cookies. Users can adjust their browser settings to
            refuse cookies or notify when cookies are being sent.
          </p>

          <h2>Changes to the Privacy Policy</h2>
          <p>
            We reserve the right to update or modify this Privacy Policy at any
            time. Users are encouraged to review this page periodically for any
            changes. By continuing to use the website after any modifications,
            users acknowledge and accept the updated Privacy Policy.
          </p>

          <h2>Contact Us</h2>
          <p>
            If you have any questions or concerns regarding this Legal
            Disclaimer, please contact us at{" "}
            <a href="mailto:apicanta.llc@gmail.com">apicanta.llc@gmail.com</a>.
            Our address is Germay Drive 3, Delaware, USA.
          </p>
        </div>
      </div>
    </div>
  );
};
