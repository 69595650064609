import { useMobile } from "../../../hooks/useMobile";
import "./WhoWeAre.css";

export const WhoWeAre = () => {
  const isMobile = useMobile();
  return (
    <div
      className={
        !isMobile
          ? "who-we-are-container-flex"
          : "who-we-are-container-flex-mobile"
      }
    >
      <div className="who-we-are-container">
        <h2 className="who-we-are-header">Who We Are</h2>
        <div className="who-we-are-content">
          <h2>Welcome to Apicanta</h2>
          <p>
            At Apicanta, we are passionate about empowering individuals with the
            knowledge and skills they need to grow their businesses. With years
            of industry experience, we have developed proven strategies and
            insights that can help entrepreneurs and business owners succeed in
            today's competitive market.
          </p>
          <p>
            Our dedicated team of experts is committed to providing high-quality
            education and support to our students. We offer a wide range of
            courses, workshops, and resources that cover essential topics such
            as marketing, sales, financial management, and leadership
            development.
          </p>
          <p>
            Our mission is to guide aspiring entrepreneurs and business owners
            through every stage of their journey, from initial idea conception
            to sustainable growth. We believe that with the right knowledge and
            guidance, anyone can achieve their business goals and unlock their
            true potential.
          </p>
        </div>
      </div>
    </div>
  );
};
