import { useMobile } from "../../../hooks/useMobile";
import "./LegalDisclaimer.css";

export const LegalDisclaimer = () => {
  const isMobile = useMobile();
  return (
    <div
      className={
        !isMobile
          ? "legal-disclaimer-container-flex"
          : "legal-disclaimer-container-flex-mobile"
      }
    >
      <div className="legal-disclaimer-container">
        <div className="legal-disclaimer-content">
          <h1>Legal Disclaimer</h1>

          <h2>Accuracy of Information</h2>
          <p>
            The information provided on this website is for general
            informational purposes only and should not be relied upon as legal,
            financial, or professional advice. While we strive to keep the
            information up to date and accurate, we make no representations or
            warranties of any kind, express or implied, about the completeness,
            accuracy, reliability, suitability, or availability with respect to
            the website or the information contained on the website.
          </p>

          <h2>No Liability</h2>
          <p>
            In no event will we be liable for any loss or damage including
            without limitation, indirect or consequential loss or damage, or any
            loss or damage whatsoever arising from loss of data or profits
            arising out of, or in connection with, the use of this website.
          </p>

          <h2>External Links</h2>
          <p>
            This website may contain links to external websites that are not
            provided or maintained by us. We do not guarantee the accuracy,
            relevance, timeliness, or completeness of any information on these
            external websites.
          </p>

          <h2>Professional Advice</h2>
          <p>
            The content of this website does not constitute professional advice.
            Users should consult with a qualified professional for specific
            advice related to their individual circumstances.
          </p>

          <h2>No Endorsement</h2>
          <p>
            The mention of specific products, services, companies, or
            individuals on this website does not constitute endorsement or
            recommendation unless explicitly stated.
          </p>

          <h2>Changes to the Disclaimer</h2>
          <p>
            We reserve the right to modify or amend this Disclaimer at any time.
            Any changes will be effective immediately upon posting on the
            website. Users are encouraged to review this Disclaimer periodically
            to stay informed about any updates.
          </p>

          <h2>Contact Us</h2>
          <p>
            If you have any questions or concerns regarding this Legal
            Disclaimer, please contact us at{" "}
            <a href="mailto:apicanta.llc@gmail.com">apicanta.llc@gmail.com</a>.
            Our address is Germay Drive 3, Delaware, USA.
          </p>
        </div>
      </div>
    </div>
  );
};
